import React, { useState } from 'react';
import { Form, Input, Button, Message } from 'semantic-ui-react';
import { findValidPromoCodeDataByCode } from '@/api/promoCodeAPI';
import { cartSelector, companySelector } from '@/store/selector';
import { useDispatch } from 'react-redux';
import { addItems, setPromoCode as setPromoCodeToCart } from '@/components/cartSlice';
import Joi from 'joi';
import ValidationHelper from '@/helpers/validationHelper';
import { createUseStyles } from 'react-jss';

const profileSchema = Joi.object({
  promoCode: Joi.string().required().messages({
    'string.base': 'Please enter a promotion code',
    'any.required': 'Please enter a promotion code',
    'string.empty': 'Please enter a promotion code',
  }),
});

const useStyles = createUseStyles(
  {
    root: {},
    applyDiscountButton: {
      height: 'max-content',
    },
  },
  { name: 'PromoCodePanel' },
);

const PromoCodePanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cart = cartSelector();
  const company = companySelector();
  const [promoCode, setPromoCode] = useState(null);
  const [errors, setErrors] = useState({
    promoCode: null,
  });
  const [loadingPromotion, setLoadingPromotion] = useState(false);

  const onChange = (_event, data) => {
    const { id, value } = data;
    setErrors({ ...errors, [id]: null });
    setPromoCode(value);
  };

  const onClickSubmit = () => {
    ValidationHelper.validateSchema(profileSchema, { promoCode: promoCode })
      .then(() => {
        let promoCodeData = {
          code: promoCode,
          itemList: cart.items,
          propertyId: company.property.id,
        };
        setLoadingPromotion(true);
        findValidPromoCodeDataByCode(promoCodeData)
          .then((response) => {
            setLoadingPromotion(false);
            if (response.data.response == 'OK') {
              dispatch(addItems(response.data.itemList));
              dispatch(setPromoCodeToCart(promoCode));
            }
          })
          .catch((e) => {
            setLoadingPromotion(false);
            setErrors({ ...errors, ['promoCode']: e.response.data });
            dispatch(setPromoCodeToCart(null));
          });
      })
      .catch(setErrors);
  };

  return (
    <Form className={classes.root}>
      <Form.Group widths={2}>
        <Form.Field
          id="promoCode"
          control={Input}
          label="Promotion Discount"
          type="text"
          onChange={onChange}
          value={promoCode}
          error={!!errors?.promoCode && !cart?.promoCode}
          placeholder="Gift Card / Promotion Code"
        />
        {!cart?.promoCode && (
          <Form.Field
            control={Button}
            label="&nbsp;"
            onClick={onClickSubmit}
            type="button"
            color="green"
            content="Apply Promotion"
            loading={loadingPromotion}
          />
        )}
      </Form.Group>
      {!!errors?.promoCode && !cart?.promoCode && <Message negative>{errors.promoCode}</Message>}
      {!!cart?.promoCode && <Message positive>Promotion {cart.promoCode} applied !</Message>}
    </Form>
  );
};

export default React.memo(PromoCodePanel);
