import React from 'react';
import { createUseStyles } from 'react-jss';
import Flickity from 'react-flickity-component';
import 'flickity-bg-lazyload';
import '@/assets/flickity.css';
import { Dimmer, Loader } from 'semantic-ui-react';

const useStyles = createUseStyles(
  {
    image: {
      opacity: 0,
      left: '0%',
      width: '100%',
      height: '100%',
      zIndex: 0,
      position: 'absolute',
      backgroundPosition: 'top left',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    imageInside: {
      width: '133%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
  },
  { name: 'Carousel' },
);

const flickityOptions = {
  initialIndex: 0,
  wrapAround: true,
  bgLazyLoad: true,
  setGallerySize: false,
  pageDots: false,
};

const Carousel = ({ defaultRoomIamgeUrl, imageList, onClickImageContainer }) => {
  const classes = useStyles();
  let style = null;

  return (
    <Flickity
      className={'slider'} // default ''
      elementType={'div'} // default 'div'
      options={flickityOptions}
      disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      static
      wrapAround={true}
      flickityRef={(c) => {
        c.on('bgLazyLoad', (event, element) => {
          var imageSrc = element.style.backgroundImage
            .replace(/url\((['"])?(.*?)\1\)/gi, '$2')
            .split(',')[0];
          var image = new Image();
          image.src = imageSrc;
          image.onload = function () {
            var width = image.width;
            var height = image.height;
            if (height > width) {
              Object.assign(element.style, {
                backgroundPositionX: '30%',
              });
            }
          };
        });
      }}
    >
      {imageList != null &&
        imageList.length > 0 &&
        imageList.map((image, idx) => (
          <div key={idx} className={classes.image}>
            <div
              className={classes.imageInside}
              data-flickity-bg-lazyload={encodeURI(image.url ? image.url : image.imageUrl)}
              onClick={onClickImageContainer}
            >
              <Dimmer active inverted>
                <Loader inverted content="Loading Image" />
              </Dimmer>
            </div>
          </div>
        ))}
      {defaultRoomIamgeUrl != null &&
        imageList != null &&
        imageList.length === 0 &&
        ((style = {
          backgroundImage: 'url("' + encodeURI(defaultRoomIamgeUrl) + '")',
        }),
        (<div className={classes.image} style={style}></div>))}
    </Flickity>
  );
};

export default React.memo(Carousel);
