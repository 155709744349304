import { createSlice } from '@reduxjs/toolkit';
import CartHelper from '@/helpers/cartHelper';

const initialState = {
  items: new Array(),
  customer: {
    country: 'United States of America',
    state: 'Alabama',
    countryAlphaCode: 'US',
  },
  payment: {
    paymentFulfilled: false,
  },
  error: {
    message: null,
  },
  reservationConfirmation: {
    //reservationNumber: 31158430,
    //city: 'Amagansett',
    //cancellationPolicy: 'Test',
  },
  canShowCheckoutButton: false,
  promoCode: null,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, bookingSearchByDatesResponseItemPayload) => {
      state.items.push({ ...bookingSearchByDatesResponseItemPayload.payload, numberOfRooms: 1 });
      if (state.items.length > 1) {
        state.promoCode = null;
      }
    },
    addItems: (state, bookingSearchByDatesResponseItemPayload) => {
      state.items = new Array();
      let itemList = bookingSearchByDatesResponseItemPayload.payload;
      console.log(itemList);
      state.items.push(...itemList);
    },
    setItemQuantity: (state, bookingSearchByDatesResponseItemPayload) => {
      var payloadItem = bookingSearchByDatesResponseItemPayload.payload.item;
      var newItemQuantity = bookingSearchByDatesResponseItemPayload.payload.newItemQuantity;
      const itemInCart = CartHelper.findItemByRoomType(state.items, payloadItem);

      if (newItemQuantity <= 0) {
        state.items = state.items.filter((item) => item.roomType.id !== payloadItem.roomType.id);
      } else {
        itemInCart.numberOfRooms = newItemQuantity;
        if (newItemQuantity > 1) {
          state.promoCode = null;
        }
      }
    },
    resetCart: (state, bookingSearchByDatesResponseItemPayload) => {
      state.items = new Array();
      if (bookingSearchByDatesResponseItemPayload.payload) {
        state.items.push({ ...bookingSearchByDatesResponseItemPayload.payload, numberOfRooms: 1 });
      }
      state.promoCode = null;
    },
    setCustomer: (state, customerPayload) => {
      state.customer = customerPayload.payload;
    },
    setPayment: (state, paymentPayload) => {
      state.payment = paymentPayload.payload;
    },
    setReservationConfirmation: (state, reservationConfirmationPayload) => {
      state.reservationConfirmation = reservationConfirmationPayload.payload;
    },
    setCartError: (state, errorPayload) => {
      state.error = errorPayload.payload;
    },
    setCanShowCheckoutButton: (state, data) => {
      state.canShowCheckoutButton = data.payload;
    },
    setPromoCode: (state, promoCodePayload) => {
      state.promoCode = promoCodePayload.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addItem,
  addItems,
  setItemQuantity,
  resetCart,
  setCustomer,
  setPayment,
  setCartError,
  setReservationConfirmation,
  setCanShowCheckoutButton,
  setPromoCode,
} = cartSlice.actions;

export default cartSlice.reducer;
