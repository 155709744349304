import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(
  {
    root: {
      display: 'block',
      // font-family: "Work-Sans", sans-serif,
      fontSize: '1rem',
      lineHeight: 'normal',
      margin: '30px 0 0',
      position: 'relative',
      '& > span': {
        display: 'inline-block',
        position: 'absolute',
        right: 0,
        top: 0,
      },
    },
  },
  { name: 'CartItemLine' },
);

const CartItemLine = ({ title, description }) => {
  const classes = useStyles();

  return (
    <p className={classes.root}>
      {title} <span>{description}</span>
    </p>
  );
};

export default React.memo(CartItemLine);
