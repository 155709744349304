import api from './api';
import DateHelper from '@/helpers/dateHelper';

const URLS = {
  getStripeCredentials: 'getStripeCredentials',
  createStripePaymentIntent: 'createStripePaymentIntent',
};

export const getStripeCredentials = (propertyId) => {
  return api.post(URLS.getStripeCredentials, { propertyId: propertyId });
};

export const createStripePaymentIntent = (
  customer,
  totalAmount,
  depositAmount,
  propertyId,
  items,
) => {
  return api.post(URLS.createStripePaymentIntent, {
    ...customer,
    totalAmount: totalAmount,
    depositAmount: depositAmount,
    propertyId: propertyId,
    itemList: items.map((item) => {
      return {
        dateRateList: item.dateRateList,
        numberOfRooms: item.numberOfRooms,
        roomTypeId: item.roomType.id,
        checkInDate: DateHelper.defaultFormat2(item.dateRateList[0].date),
        checkOutDate: DateHelper.defaultFormat2(
          DateHelper.addDay(item.dateRateList[item.dateRateList.length - 1].date),
        ),
        people: item.people,
        cleaningFees: item.chargesAndTaxes.cleaningFees,
      };
    }),
  });
};
