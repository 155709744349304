import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Grid, GridColumn } from 'semantic-ui-react';
import Colors from '@/constants/colors';
import CheckoutPanel from '@/components/checkout/CheckoutPanel';
import LeftCheckoutPanel from '@/components/checkout/LeftCheckoutPanel';
import RightCheckoutPanel from '@/components/checkout/RightCheckoutPanel';
import { Elements } from '@stripe/react-stripe-js';
import { cartSelector } from '@/store/selector';
import { setCartError } from '@/components/cartSlice';
import CartHelper from '@/helpers/cartHelper';
import { getStripeCredentials } from '@/api/stripeAPI';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';
import '../common/Search.css';

const useStyles = createUseStyles(
  {
    checkoutPanel: {
      position: 'fixed',
      width: '100% !important',
      height: '100%',
      backgroundColor: Colors.whiteOne,
      bottom: '75px',
      left: '0',
      transform: 'translateY(100%)',
      transition: 'transform .75s cubic-bezier(.9,1,.32,1)',
      zIndex: 9999999,
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      display: 'none',
    },
    checkoutPanelShowed: {
      composes: ['$checkoutPanel'],
      display: 'block',
    },
    checkoutPanelActive: {
      composes: ['$checkoutPanel'],
      transform: 'translateY(calc(0% + 75px)) !important',
      display: 'block',
    },
    checkoutPanelTop: {
      width: '100%',
      height: '75px',
      backgroundColor: '#000',
      color: '#fff',
      textAlign: 'center',
      padding: '25px',
      cursor: 'pointer',
    },
    checkoutBack: {
      display: 'none',
    },
    checkoutBackActive: {
      composes: ['$checkoutBack'],
      display: 'block',
    },
  },
  { name: 'CheckOutBar' },
);
const CheckOutBar = ({ setLoading }) => {
  const classes = useStyles();
  const cart = cartSelector();
  const dispatch = useDispatch();

  const { total } = CartHelper.cartSummary(cart);
  const [stripePromise, setStripePromise] = useState(null);
  const [openCheckout, setOpenCheckout] = useState(false);

  let checkoutPanelClass = classes.checkoutPanel;
  if (cart.items !== null && cart.items.length > 0) {
    checkoutPanelClass = classes.checkoutPanelShowed;
  }
  if (openCheckout) {
    checkoutPanelClass = classes.checkoutPanelActive;
  }

  const handleLoading = () => {
    setLoading(false);
  };

  const onStripeCredentialError = (error) => {
    dispatch(setCartError({ message: error.response.data }));
  };

  useEffect(() => {
    if (openCheckout) {
      dispatch(setCartError({ message: null }));
      let property = CartHelper.findCartProperty(cart);
      if (property) {
        getStripeCredentials(property.id)
          .then((res) => {
            let stripeCredentials = res.data;
            setStripePromise(loadStripe(stripeCredentials.publicKey));
          })
          .catch(onStripeCredentialError);
      }
      if (document.readyState === 'complete') {
        handleLoading();
      } else {
        window.addEventListener('load', handleLoading, { capture: false, passive: true });
        return () => {
          window.removeEventListener('load', handleLoading);
        };
      }
    }
  }, [openCheckout]);

  return (
    <div className={checkoutPanelClass}>
      <div className={classes.checkoutPanelTop}>
        <Grid columns="equal">
          <GridColumn textAlign="left" onClick={() => setOpenCheckout(false)}>
            <div className={openCheckout ? classes.checkoutBackActive : classes.checkoutBack}>
              <i className="arrow left icon"></i> Back
            </div>
          </GridColumn>
          <GridColumn textAlign="center" onClick={() => setOpenCheckout(true)}>
            Checkout
          </GridColumn>
          <GridColumn textAlign="right">
            <span>${total}</span> <i className="cart icon cart-icon"></i>
          </GridColumn>
        </Grid>
      </div>
      <div className="check-out-grid-content">
        <Elements stripe={stripePromise}>
          <CheckoutPanel
            leftPanel={<LeftCheckoutPanel />}
            rightPanel={<RightCheckoutPanel />}
          ></CheckoutPanel>
        </Elements>
      </div>
    </div>
  );
};
export default React.memo(CheckOutBar);
