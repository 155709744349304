import api from './api';
import DateHelper from '@/helpers/dateHelper';

const URLS = {
  createReservation: 'createReservation',
};

export const createReservation = (
  customer,
  totalAmount,
  depositAmount,
  propertyId,
  amountPaid,
  paymentIntentId,
  items,
  promoCode,
) => {
  return api.post(URLS.createReservation, {
    ...customer,
    totalAmount: totalAmount,
    depositAmount: depositAmount,
    propertyId: propertyId,
    amountPaid: amountPaid,
    paymentIntentId: paymentIntentId,
    promoCode: promoCode,
    itemList: items.map((item) => {
      return {
        dateRateList: item.dateRateList,
        numberOfRooms: item.numberOfRooms,
        roomTypeId: item.roomType.id,
        checkInDate: DateHelper.defaultFormat2(item.dateRateList[0].date),
        checkOutDate: DateHelper.defaultFormat2(
          DateHelper.addDay(item.dateRateList[item.dateRateList.length - 1].date),
        ),
        people: item.people,
        cleaningFees: item.chargesAndTaxes.cleaningFees,
      };
    }),
  });
};
