import { round } from 'lodash';

const findItemByRoomType = (itemList, bookingSearchByDatesResponseItem) => {
  var isInCart = undefined;
  itemList.forEach((item) => {
    if (item.roomType.id == bookingSearchByDatesResponseItem.roomType.id) {
      isInCart = item;
    }
  });
  return isInCart;
};

const findCartProperty = (cart) => {
  var cartProperty = undefined;
  let itemList = cart.items;
  if (itemList && itemList.length > 0) {
    cartProperty = itemList[0].roomType.property;
  }
  return cartProperty;
};

const cartSummary = (cart) => {
  let roomQty = 0;
  let total = 0;
  let due = 0;

  cart.items.forEach((item) => {
    roomQty += item.numberOfRooms;
    total += item.chargesAndTaxes.totalCharges * item.numberOfRooms;
    due += item.chargesAndTaxes.depositAmount * item.numberOfRooms;
  });

  return {
    roomQty: round(roomQty, 2),
    total: round(total, 2).toFixed(2),
    due: round(due, 2).toFixed(2),
  };
};

const isCustomerValid = (customer) => {
  const { firstName, lastName, phoneNumber, country, city, address, email } = customer;
  return !(!firstName || !lastName || !phoneNumber || !country || !city || !address || !email);
};

const isPaymentValid = (payment) => {
  /*const { nameOnCard, cardNumber, expirationMonth, expirationYear, cvv, postalCode } = payment;
  return !(
    !nameOnCard ||
    !cardNumber ||
    !expirationMonth ||
    !expirationYear ||
    !cvv ||
    !postalCode
  );*/
  return payment.paymentFulfilled;
};

export default {
  findItemByRoomType,
  cartSummary,
  isCustomerValid,
  isPaymentValid,
  findCartProperty,
};
