import React from 'react';
import { createUseStyles } from 'react-jss';
import { Container, Grid, GridColumn, Menu } from 'semantic-ui-react';
import './Top.css';
import { useDispatch } from 'react-redux';
import { selectItem, setShowAbout, setShowPolicies } from '@/components/menuSlice';
import { MENU_ITEMS } from 'utils/constants';
import WeatherPanel from '@/components/common/WeatherPanel';
import { companySelector } from '@/store/selector';
import { menuSelector } from '@/store/selector';
import { Link } from 'react-router-dom';

const useStyles = createUseStyles(
  {
    root: {
      minHeight: '100vh',
      height: '100%',
    },
    logo: {
      maxHeight: '55px',
      marginTop: '6px',
    },
    topLeft: {},
    topCenter: {},
    topRight: {},
    container: {
      width: '100% !important',
    },
  },
  { name: 'Top' },
);
const Top = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const company = companySelector();
  const menu = menuSelector();
  const property = company.property;
  const defaultLink = menu?.friendlyUrl
    ? '/' + menu.friendlyUrl
    : '/availability/property?property=' + property.id;

  const menuSelected = (item) => {
    dispatch(
      selectItem({
        item: item,
      }),
    );
    if (item === MENU_ITEMS.ABOUT) {
      dispatch(
        setShowAbout({
          showAbout: true,
        }),
      );
    }
    if (item === MENU_ITEMS.POLICIES) {
      dispatch(
        setShowPolicies({
          showPolicies: true,
        }),
      );
    }
  };
  return (
    property && (
      <div className={classes.top}>
        <Container className={classes.container}>
          <a className="menu-responsive" onClick={() => menuSelected(MENU_ITEMS.MENU)}>
            <i className="bars right icon"></i>{' '}
          </a>
          <Grid columns={3} className="menu-grid">
            <GridColumn textAlign="left">
              <WeatherPanel></WeatherPanel>
            </GridColumn>

            <GridColumn textAlign="center">
              <Link to={defaultLink}>
                {property.logo != null && (
                  <img className={classes.logo} alt="logo" src={property.logo}></img>
                )}
                {property.logo == null && <div className="property-logo-name">{property.name}</div>}
              </Link>
            </GridColumn>
            {props.showMenu && (
              <GridColumn textAlign="right" className='menu-grid-items'>
                <Menu>
                  <Menu.Item
                    name="book-now"
                    position="right"
                    onClick={() => menuSelected(MENU_ITEMS.BOOK)}
                  >
                    BOOK NOW
                  </Menu.Item>
                  <Menu.Item name="rooms" onClick={() => menuSelected(MENU_ITEMS.ROOMS)}>
                    ROOMS
                  </Menu.Item>
                  <Menu.Item name="contact" onClick={() => menuSelected(MENU_ITEMS.CONTACT)}>
                    CONTACT
                  </Menu.Item>
                  {((property.propertyPoliciesData.general != null &&
                    property.propertyPoliciesData.general != '') ||
                    (property.propertyPoliciesData.cancellation != null &&
                      property.propertyPoliciesData.cancellation != '') ||
                    (property.propertyPoliciesData.guarantee != null &&
                      property.propertyPoliciesData.guarantee != '') ||
                    (property.propertyPoliciesData.amenities != null &&
                      property.propertyPoliciesData.amenities != '')) && (
                    <Menu.Item name="policies" onClick={() => menuSelected(MENU_ITEMS.POLICIES)}>
                      POLICIES
                    </Menu.Item>
                  )}
                  {property.companyAbout != null && property.companyAbout != '' && (
                    <Menu.Item name="about" onClick={() => menuSelected(MENU_ITEMS.ABOUT)}>
                      ABOUT
                    </Menu.Item>
                  )}
                </Menu>
              </GridColumn>
            )}
          </Grid>
        </Container>
      </div>
    )
  );
};
export default React.memo(Top);
