import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Container, Form } from 'semantic-ui-react';
import 'flatpickr/dist/themes/airbnb.css';
import './Search.css';
import { listRoomTypeByAvailability } from '@/api/roomTypeAPI';
import { availability } from '@/api/roomTypeAPI';
import { listPropertyByCompany } from '@/api/propertyAPI';
import { useDispatch } from 'react-redux';
import { submitSearch } from '@/components/searchSlice';
import { useLocation } from 'react-router-dom';
import Colors from '@/constants/colors';
import { menuSelector } from '@/store/selector';
import { sessionSelector } from '@/store/selector';
import { MENU_ITEMS } from 'utils/constants';
import { searchSelector } from '@/store/selector';
import { companySelector } from '@/store/selector';
import { setRoomType } from '@/components/searchSlice';
import { setRates } from '@/components/searchSlice';
import { setAvailability } from '../searchSlice';
import { setCanShowCheckoutButton } from '../cartSlice';
import { cartSelector } from '@/store/selector';
import { selectItem } from '@/components/menuSlice';
import ResponsiveDatesPanel from '@/components/search/ResponsiveDatesPanel';
import SearchPanel from '@/components/search/SearchPanel';
import SearchBottomBar from '../search/SearchBottomBar';
import SearchResultPanel from '../search/SearchResultPanel';
import ResponsiveSearchPanel from '../search/ResponsiveSearchPanel';
import SearchNoResultPanel from '../search/SearchNoResultPanel';
import CheckOutBar from '../checkout/CheckOutBar';
import isMobile from '@/helpers/mobileHelper';
import { setResultPanelOpen } from '@/components/searchSlice';

const useStyles = createUseStyles(
  {
    search: (properties) => ({
      '& .selected, .startRange, .endRange, .selected:hover, .startRange:hover, .endRange:hover, .today:hover':
        {
          backgroundColor: properties.propertyColor,
          borderColor: properties.propertyColor + ' !important',
          color: properties.fontColor + ' !important',
        },
      '& .today': {
        borderBottomColor: properties.propertyColor + ' !important',
      },
    }),
    searchLeft: {
      borderRight: `1px solid ${Colors.grayFour};`,
    },
    content: {
      display: 'inline-block',
      width: '100%',
      paddingTop: '25px',
    },
  },
  { name: 'Search' },
);
const Search = ({ prop, handleItemSelected }) => {
  const location = useLocation();
  const parameters = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const menu = menuSelector();
  const search = searchSelector();
  const cart = cartSelector();
  const session = sessionSelector();
  const company = companySelector();
  const classes = useStyles({
    propertyColor: search.property != null ? search.property.color : '',
    fontColor: search.property != null ? search.property.fontColor : '',
  });
  const [show, setShow] = useState({ show: false });
  const [showResponsive, setShowResponsive] = useState({ showResponsive: false });
  const [showDatesPanel, setShowDates] = useState({ showDatesPanel: false });
  const [showNoResult, setShowNoResult] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [adults, setAdults] = useState(2);
  const [childenrs, setChildrens] = useState(0);
  const [searchParams, setSearchParams] = useState({ arrival: null, departure: null });
  const [properties, setProperties] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [property, setProperty] = useState({ id: null, name: null });
  const [externalUrlFulfilled, setExternalUrlFulfilled] = useState(false);
  const [defaultResults, setDefaultResults] = useState(null);

  if (property.id === null) {
    setProperty({ id: prop.id, name: prop.name, defaultRoomTypeImage: prop.defaultRoomIamgeUrl });
  }
  //const showCheckout = cart.items !== null && cart.items.length > 0;

  const viewAllRoomsClick = () => {
    setShow(false);
    dispatch(
      selectItem({
        item: MENU_ITEMS.ROOMS,
      }),
    );
    removeRoomType();
  };
  const viewAllRoomsResponsiveClick = () => {
    setShowResponsive(false);
    dispatch(
      selectItem({
        item: MENU_ITEMS.ROOMS,
      }),
    );
  };

  const divCLick = () => {
    if (showResults === null || showResults === false) {
      setShow(true);
    }
    if (cart.canShowCheckoutButton) {
      backClick();
      setShow(true);
    }
  };
  const backClick = () => {
    setShowResults(false);
    dispatch(setResultPanelOpen(false));
    dispatch(setCanShowCheckoutButton(false));
  };
  const viewAllClick = () => {
    removeRoomType();
    onSearchClick({ withoutRoomType: true });
  };
  const bookResponsiveCLick = () => {
    setShowResponsive(true);
  };
  const datesCLick = () => {
    setShowDates(true);
  };
  const addAdultsValue = () => {
    setAdults(adults + 1);
  };
  const subtractAdultsValue = () => {
    if (adults - 1 > 0) {
      setAdults(adults - 1);
    }
  };
  const addChildrensValue = () => {
    setChildrens(childenrs + 1);
  };
  const subtractChildrensValue = () => {
    if (childenrs - 1 >= 0) {
      setChildrens(childenrs - 1);
    }
  };
  const datesChanged = ([arrival, departure]) => {
    setSearchParams({ arrival: arrival, departure: departure });
    if (arrival != null && departure != null) {
      setShowDates(false);
    }
  };
  const propertySelected = (p) => {
    setProperty({ id: p.id, name: p.name, defaultRoomTypeImage: p.defaultRoomIamgeUrl });
    // navigate('/availability/property/?property=' + p.id);
  };
  const allResortsClicked = () => {
    setProperty({ id: null, name: null, defaultRoomTypeImage: null });
  };
  const removeRoomType = () => {
    dispatch(
      setRoomType({
        roomType: null,
      }),
    );
  };
  const onSearchClick = ({ withoutRoomType }) => {
    setLoading(true);
    setShowNoResult(false);
    var params = {
      propertyIdList: property.id !== null ? [property.id] : [],
      roomTypeIdList:
        (!withoutRoomType || withoutRoomType === null) && search.roomType !== null
          ? [search.roomType.id]
          : [],
      checkInDate: searchParams.arrival?.toISOString(true),
      checkOutDate: searchParams.departure?.toISOString(true),
      people: adults + childenrs,
    };
    if (!params.checkInDate || !params.checkOutDate) {
      setLoading(false);
      return;
    }
    listRoomTypeByAvailability(params)
      .then((response) => {
        const bookingSearchByDatesResponseItems = response.data.itemList;
        if (bookingSearchByDatesResponseItems.length == 0) {
          setShowNoResult(true);
        } else {
          setShowResults(true);
          dispatch(setResultPanelOpen(true));
          dispatch(setCanShowCheckoutButton(true));
        }
        dispatch(
          submitSearch({
            arrival: searchParams.arrival?.toISOString(true),
            departure: searchParams.departure?.toISOString(true),
            people: adults + childenrs,
          }),
        );
        setDefaultResults(bookingSearchByDatesResponseItems);
      })
      .finally(() => {
        setShow(false);
        setLoading(false);
      });
  };
  const params = {
    companyId: '1',
  };

  useEffect(() => {
    setShowResponsive(false);
    listPropertyByCompany(params).then((res) => {
      setProperties(res.data);
      if (company?.property) {
        let propertyId = company.property.id;
        res.data.forEach((property) => {
          if (propertyId == property.id) {
            setProperty({
              id: property.id,
              name: property.name,
              defaultRoomTypeImage: property.defaultRoomIamgeUrl,
            });
          }
        });
      }

      let adult = null;
      if (parameters.get('adult') || parameters.get('adults')) {
        adult = parameters.get('adult')
          ? parseInt(parameters.get('adult'), 0)
          : parseInt(parameters.get('adults'), 0);
        setAdults(adult);
      }

      let checkin = null;
      let checkout = null;
      if (parameters.get('checkin') && parameters.get('checkout')) {
        checkin = new Date(Number(parameters.get('checkin')) * 1000);
        checkout = new Date(Number(parameters.get('checkout')) * 1000);
        checkin = new Date(checkin.valueOf() + checkin.getTimezoneOffset() * 60000);
        checkout = new Date(checkout.valueOf() + checkout.getTimezoneOffset() * 60000);
        datesChanged([checkin, checkout]);
      }

      if (adult && checkin && checkout) {
        setExternalUrlFulfilled(true);
      }
    });
  }, [session]);

  useEffect(() => {
    if (externalUrlFulfilled) {
      if (isMobile.matches) {
        setShowResponsive(true);
      }
      onSearchClick({ withoutRoomType: false });
    }
  }, [externalUrlFulfilled]);

  useEffect(() => {
    if (menu.item === MENU_ITEMS.BOOK) {
      setShow(true);
    }
    if (menu.item === MENU_ITEMS.BOOKRESPONSIVE) {
      setShowResponsive(true);
    }
  }, [menu.item]);

  useEffect(() => {
    setShow(menu.showSearch);
  }, [menu.showSearch]);

  useEffect(() => {
    if (search.roomType !== null) {
      if (search.windowSize != null && search.windowSize < 1200) {
        setShowResponsive(true);
      } else {
        setShow(true);
      }
      setLoadingCalendar(true);
      var params = {
        roomTypeIdList: search.roomType !== null ? [search.roomType.id] : [],
      };
      availability(params)
        .then((response) => {
          let rates = [];
          for (let i = 0; i < response.data.itemList.length; i++) {
            rates[response.data.itemList[i].date] = response.data.itemList[i].rate;
          }
          let availability = [];
          for (let i = 0; i < response.data.itemList.length; i++) {
            if (response.data.itemList[i].roomsAvailable > 0) {
              availability.push(response.data.itemList[i].date);
            }
          }
          dispatch(
            setRates({
              rates: rates,
            }),
          );
          dispatch(
            setAvailability({
              availability: availability,
            }),
          );
        })
        .finally(() => {
          setLoadingCalendar(false);
        });
    } else {
      var propertyParams = {
        propertyIdList: search.property !== null ? [search.property.id] : [],
      };
      availability(propertyParams)
        .then((response) => {
          let availability = [];
          for (let i = 0; i < response.data.itemList.length; i++) {
            //if (response.data.itemList[i].roomsAvailable > 0) {
            availability.push(response.data.itemList[i].date);
            //}
          }
          dispatch(
            setAvailability({
              availability: availability,
            }),
          );
        })
        .finally(() => {});
    }
  }, [search.roomType]);

  return (
    <div className={classes.search}>
      {showResponsive === false && (
        <a className="book-responsive" onClick={bookResponsiveCLick}>
          Book
        </a>
      )}
      {showResponsive === true && (
        <Form className="book-responsive" loading={loading} onClick={onSearchClick}>
          <div>Search</div>
        </Form>
      )}
      <ResponsiveSearchPanel
        showResponsive={showResponsive}
        setShowResponsive={setShowResponsive}
        searchParams={searchParams}
        viewAllRoomsResponsiveClick={viewAllRoomsResponsiveClick}
        datesCLick={datesCLick}
        adults={adults}
        childenrs={childenrs}
        viewAllRoomsClick={viewAllRoomsClick}
        propertySelected={propertySelected}
        removeRoomType={removeRoomType}
        subtractAdultsValue={subtractAdultsValue}
        addAdultsValue={addAdultsValue}
        subtractChildrensValue={subtractChildrensValue}
        addChildrensValue={addChildrensValue}
      ></ResponsiveSearchPanel>
      <ResponsiveDatesPanel
        showDatesPanel={showDatesPanel}
        loadingCalendar={loadingCalendar}
        setShowDates={setShowDates}
        datesChanged={datesChanged}
      ></ResponsiveDatesPanel>
      <SearchPanel
        show={show}
        loadingCalendar={loadingCalendar}
        setShow={setShow}
        datesChanged={datesChanged}
        property={property}
        allResortsClicked={allResortsClicked}
        properties={properties}
        adults={adults}
        childenrs={childenrs}
        viewAllRoomsClick={viewAllRoomsClick}
        propertySelected={propertySelected}
        removeRoomType={removeRoomType}
        subtractAdultsValue={subtractAdultsValue}
        addAdultsValue={addAdultsValue}
        subtractChildrensValue={subtractChildrensValue}
        addChildrensValue={addChildrensValue}
      ></SearchPanel>
      <SearchNoResultPanel
        showNoResult={showNoResult}
        setShowNoResult={setShowNoResult}
      ></SearchNoResultPanel>
      <SearchBottomBar
        showResults={showResults}
        divClick={divCLick}
        viewAllClick={viewAllClick}
        backClick={backClick}
        onSearchClick={onSearchClick}
        loading={loading}
        property={property}
        searchParams={searchParams}
        adults={adults}
        childenrs={childenrs}
      ></SearchBottomBar>
      <SearchResultPanel
        showResults={showResults}
        backClick={backClick}
        handleItemSelected={handleItemSelected}
        property={property}
        results={defaultResults}
      ></SearchResultPanel>
      <CheckOutBar setLoading={setLoading}></CheckOutBar>
      <Container
        className={
          loading === true ? ['search-dimmer', 'search-dimmer-active'].join(' ') : 'search-dimmer'
        }
      ></Container>
    </div>
  );
};
export default React.memo(Search);
