import React, {useState, useEffect} from 'react';
import { createUseStyles } from 'react-jss';
import { menuSelector } from '@/store/selector';
import { MENU_ITEMS } from 'utils/constants';
import { useDispatch } from 'react-redux';
import { selectItem, setShowAbout } from '@/components/menuSlice';
import './MenuResponsive.css';

const useStyles = createUseStyles(
  {
    about: {
      paddingLeft: '25px'
    },
    menuResponsivePanel: {
      position: 'fixed',
      width: '100% !important',
      height: '100% !important',
      backgroundColor: '#fff',
      left: '0',
      top: '66px',
      transform: 'translateX(-138%)',
      transition: 'transform .75s cubic-bezier(.23,1,.32,1)',
      zIndex: 999,
      borderTop: '1px solid #ccc'
    },
    menuResponsivePanelActive: {
      composes: ['$menuResponsivePanel'],
      transform: 'translateX(0) !important',
    },
    address: {
      marginTop: '8px',
      paddingLeft: '5px',
      fontSize: '12px'
    },
    addressContent: {
      display: 'flex',
      paddingLeft: '25px'
    }
  },
  { name: 'MenuResponsive' },
);
const MenuResponsive = ({property}) => {
   const classes = useStyles();
   const menu = menuSelector();
   const [show, setShow] = useState({ show: false });
   const dispatch = useDispatch();
   const menuSelected = (item) => {
    setShow(false);
    dispatch(
      selectItem({
        item: item,
      }),
    );
    if (item === MENU_ITEMS.ABOUT) {
      dispatch(
        setShowAbout({
          showAbout: true,
        }),
      );
    }
  };
  
  useEffect(() => {
    if (menu.item === MENU_ITEMS.MENU){
      setShow(!show);
    }
  }, [menu.item]);
    return (
    <div className={ show === true ? classes.menuResponsivePanelActive : classes.menuResponsivePanel}>
      <ul className='menu-responsive-list'>
        <li onClick={() => menuSelected(MENU_ITEMS.BOOKRESPONSIVE)}>Book Now</li>
        <li onClick={() => menuSelected(MENU_ITEMS.ROOMS)}>Rooms</li>
        <li onClick={() => menuSelected(MENU_ITEMS.CONTACT)}>Contact</li>
        {((property.propertyPoliciesData.general != null &&
            property.propertyPoliciesData.general != '') ||
            (property.propertyPoliciesData.cancellation != null &&
            property.propertyPoliciesData.cancellation != '') ||
            (property.propertyPoliciesData.guarantee != null &&
            property.propertyPoliciesData.guarantee != '') ||
            (property.propertyPoliciesData.amenities != null &&
            property.propertyPoliciesData.amenities != '')) && (
            <li onClick={() => menuSelected(MENU_ITEMS.POLICIES)}>Policies</li>
        )}            
      </ul>
    </div>
  );
};
export default React.memo(MenuResponsive);
